import { Case, Languages } from 'types/cases';

type Props = {
    draftCase: any;
    selectedAccounts: string[];
    languages: Languages[] | undefined;
};

const getFormUpdatedValues = ({
    draftCase,
    selectedAccounts,
    languages,
}: Props) => {
    const formUpdatedValues: Case = {
        caseTitle: draftCase?.caseTitle,
        selectedAccount: draftCase?.ownerInfo?.accountId || selectedAccounts[0],
        caseDescription: draftCase?.caseDescription,
        product: draftCase?.product,
        productId: draftCase?.productId,
        productVersion: draftCase?.productVersion,
        operatingSystem: draftCase?.operatingSystem,
        language: languages ? draftCase?.language || languages?.[0].value : '',
        attachments: draftCase?.attachments || [],
        account: draftCase?.account,
        draftCaseId: draftCase?.id,
    };

    return formUpdatedValues;
};

export { getFormUpdatedValues };
